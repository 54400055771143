<template>
  <div>
    <b-form class="mb-2" inline>
      
      <div>
        <b-form-input @blur="search" 
        v-model="filter.text" 
        class="w-100" 
        :placeholder="$t('FILTER_SEARCH_LABEL', {catalog: $t('COMPANY')}) | capitalize"
        id="search-input"></b-form-input>
      </div>

      <b-form-select v-if="!onlyActive" @change="search" v-model="filter.status" class="w-25" id="status-select">
        <b-form-select-option :value="undefined">{{ $t('FILTER_ALL_LABEL', {catalog: $t('COMPANY'), gender: 'o'})  | capitalize}}</b-form-select-option>
        <b-form-select-option :value="true">{{ $t('FILTER_ACTIVE_LABEL', {catalog: $t('COMPANY'), gender: 'o'})  | capitalize}}</b-form-select-option>
        <b-form-select-option :value="false">{{ $t('FILTER_INACTIVE_LABEL', {catalog: $t('COMPANY'), gender: 'o'})  | capitalize}}</b-form-select-option>
      </b-form-select>
    </b-form>
  </div>
</template>

<script>
import {VModel, CapitalFilter} from "@/mixins";

export default {
  name: "CompanyListFilter",
  props: ['onlyActive'],
  mixins: [VModel, CapitalFilter],
  data() {
    return {
      filter: {}
    }
  },
  methods: {
    search() {
      if(this.onlyActive) {
        this.filter.status = true;
      }
      this.$emit('search', this.filter);
    }
  }
}
</script>

<style scoped>

</style>